// var url = 'http://pristyn-java-staging-1025160438.ap-south-1.elb.amazonaws.com/oauth/token'
// var url = 'https://pristyn-java-staging-1025160438.ap-south-1.elb.amazonaws.com/partnerportal/auth/generateToken'
// var url = 'https://java-staging.pristoncare.com/partnerportal/auth/generateToken'
// var url = 'https://OverhaulLB-1186054598.ap-south-1.elb.amazonaws.com/partnerportal/auth/generateToken'
var url = 'https://pristyntech.com/partnerportal/auth/generateToken'
var body = document.querySelector('body')

function handleCredentialResponse(googleRes) {
  console.log(googleRes)

  var reqBody = "credential=" + googleRes.credential + "&clientId=" + googleRes.clientId + "&grant_type=credential"
  var raw = JSON.stringify({
    "credential": googleRes.credential,
    "clientId": googleRes.clientId,
    "grantType": "credential"
  })
  addLoader(loaderCreated());
  fetch(url, {
    method: 'post',
    body: raw,
    headers: {
      // Authorization: "Basic " + btoa("android-client:android-secret"),
      "Content-Type": "application/json",
    }
  })
    .then((res) => {
      return res.json()
    })
    .then(data => {
      // toastr('Logged In')
      console.log(data)
      if (data['status'] == "Failed"){
        toastr(data['description'],'error')
      }else{
        localStorageInit(data['result'])
      }
    })
    .catch((error) => {
      alert(error)
      console.log(error)
    })
    .finally(() => {
      removeLoader()
    })
}

// function routingHelper(otherRoles) {
//   // if (!otherRoles && !otherRoles.length) {
//   //   toastr('User not authorised','info')
//   //   return;
//   // }
//   var baseUrl = window.location.href.replace('login', "")
//   window.location.href = baseUrl + 'leads'
//   console.log('LLLLLL');
//   toastr('login successful')
// }



function toastr(message, type = 'success') {
  var modal = document.createElement("div")
  modal.classList.add('login-modal')
  modal.innerText = message
  modal.style.zIndex = 20000
  switch (type) {
    case 'success':
      modal.style.backgroundColor = '#54a254'
      break;
    case 'error':
      modal.style.backgroundColor = '#bd362f'
      break;
    case 'info':
      modal.style.backgroundColor = '#2f96b4'
      break;
    case 'warning':
      modal.style.backgroundColor = '#f89406'
      break;
    default:
      modal.style.backgroundColor = 'green'
      break;
  }
  body.appendChild(modal)
  modal.addEventListener('click', () => {
    body.removeChild(modal)
  })
  setTimeout(() => {
    var modalRef = document.querySelector('.login-modal')
    if (modalRef) {
      body.removeChild(modalRef)
    }
  }, 3000)
}

function loaderCreated() {
  var loaderDiv = document.createElement('div')
  loaderDiv.id = 'login-loader'
  loaderDiv.classList.add('loader-conatiner')
  var loader = document.createElement('div')
  loader.classList.add('custom-loader')
  loaderDiv.appendChild(loader)
  return loaderDiv
}

function addLoader(loaderDiv) {
  if (loaderDiv) {
    body.appendChild(loaderDiv)
  }
}

function removeLoader() {
  var loaderDiv = document.querySelector('#login-loader')
  if (loaderDiv) {
    body.removeChild(loaderDiv)
  }
}

function handlePasswordResponse(email, password) {
  var reqBody = "username=" + email + "&password=" + password + "&grant_type=password"
  addLoader(loaderCreated());
  fetch(url, {
    method: 'post',
    body: reqBody,
    headers: {
      Authorization: "Basic " + btoa("android-client:android-secret"),
      "Content-Type": "application/x-www-form-urlencoded",
    }
  })
    .then((res) => {
      return res.json()
    })
    .then(data => {
      toastr('Logged In')
      localStorageInit(data)
    })
    .catch((err) => {
      toastr('Server Error', 'error')
    })
    .finally(() => {
      removeLoader()
    })
}

function localStorageInit(apiResponse) {

  for (let key in apiResponse) {
    localStorage.setItem(key, JSON.stringify(apiResponse[key]))
    if (key == 'roles') {
      if ((apiResponse[key].indexOf('offline-partner-admin') > -1)) {
        localStorage.setItem("isLogin", "true")
        localStorage.setItem("isAdmin", "true")
        localStorage.setItem("isOfflinePartner", "true")
        window.location.href = '/lead-visibility';
      } else if ((apiResponse[key].indexOf('offline-partner') > -1)) {
        localStorage.setItem("isLogin", "true")
        localStorage.setItem("isAdmin", "false")
        window.location.href = '/lead-visibility';
      } else if ((apiResponse[key].indexOf('offline-partner-lybrate') > -1)) {
        localStorage.setItem("isLogin", "true")
        localStorage.setItem("isAdmin", "false")
        localStorage.setItem("isOfflinePartner", "true")
        window.location.href = '/offline-doctor';
      } else if ((apiResponse[key].indexOf('offline-partner-lybrate-super-admin') > -1)) {
        localStorage.setItem("isLogin", "true")
        localStorage.setItem("isAdmin", "true")
        localStorage.setItem("isOfflinePartner", "true")
        localStorage.setItem("offline-partner-lybrate-super-admin",'true')
        window.location.href = '/lead-visibility';
      }
      else {
        toastr("No access for Partner Onboarding Dashboard")
      }
    }
  }
}
